export default {
  props: {
    autoFocus: {
      type: Boolean,
      default: false
    },
    thin: {
      type: Boolean,
      default: false
    },
    alwaysClearable: {
      type: Boolean,
      default: false
    },
    fillWidth: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    name: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tabindex: {
      type: [String, Number],
      default: 0
    },
    content: {
      type: String,
      default: ''
    }
  },
  mounted () {
    if (this.autoFocus) {
      this.$nextTick(()=>{
        this.$refs.input.focus()
      })
    }
  },
  data () {
    return {
      value: this.content,
      valid: true,
      externalUpdate: false
    }
  },
  methods: {
    clear () {
      this.value = ''
      this.$refs.input.value = ''
      this.$refs.input.focus()
      this.$emit('clear', null)
      this.$emit('input', null)
    },
    focus () {
      // shoud be overriden
      this.$refs.input.focus()
    },
    blur () {
      // shoud be overriden
      this.$refs.input.blur()
    },
    nextTabindex (i) {
      return i
    },
    getInputElement () {
      // shoud be overriden
      return this.$refs.input
    }
  },
  watch: {
    value (n) {
      if (!this.externalUpdate) {
        this.$emit('input', n)
      }
    },
    content (n) {
      this.externalUpdate = true
      this.value = n
      this.externalUpdate = false
    }
  }
}
